import { datadogLogs } from '@datadog/browser-logs';
import { LogsEvent } from '@datadog/browser-logs/cjs/logsEvent.types';
import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';
import { RumEvent } from '@datadog/browser-rum-core/cjs/rumEvent.types';

import { cookieConsentReady } from 'components/CookieConsent';

import {
  createBeforeSend,
  filterCspReportErrors,
  filterThirdPartyForwardedJsErrors,
  filterVungleErrors,
  logWarningOnThirdPartyJsError,
} from './beforeSend';

/* eslint-disable @typescript-eslint/prefer-nullish-coalescing -- here we do need to account for empty strings and default them as well */
export const DATADOG_APPLICATION_ID =
  process.env.DATADOG_APPLICATION_ID || '9ff0363b-2a07-44d3-9ba7-0ce941004679';
export const DATADOG_CLIENT_TOKEN =
  process.env.DATADOG_CLIENT_TOKEN || 'pub688053cdcafcb1430f4e12d58b77537a';
export const DATADOG_ENABLED = process.env.DATADOG_ENABLED || false;
export const DATADOG_SAMPLE_RATE =
  Number.isNaN(Number(process.env.DATADOG_SAMPLE_RATE)) === false
    ? Number(process.env.DATADOG_SAMPLE_RATE)
    : 0;
export const DATADOG_SITE = process.env.DATADOG_SITE || 'datadoghq.com';
/* eslint-enable @typescript-eslint/prefer-nullish-coalescing */

export const initializeDatadog = ({
  allowedTracingOrigins,
  applicationId,
  clientToken,
  env,
  sessionSampleRate,
  service,
  site,
  tenant,
  version,
}: {
  allowedTracingOrigins?: RumInitConfiguration['allowedTracingOrigins'];
  applicationId: string;
  clientToken: string;
  env: string;
  sessionSampleRate?: number;
  service: string;
  site?: string;
  tenant: string;
  version?: string;
}) => {
  cookieConsentReady()
    .then(() => {
      const datadogLogsBeforeSend = createBeforeSend<LogsEvent>([
        filterThirdPartyForwardedJsErrors,
      ]);
      datadogLogs.init({
        beforeSend: datadogLogsBeforeSend,
        clientToken,
        env,
        forwardErrorsToLogs: true,
        // CSP violations are not forwarded to Datadog and instead reported to
        // Cloudflare due to their Page Shield requiring a `script-src 'none'`
        // policy which is incredibly noisy within Datadog.
        //
        // N.B. May need revisited when an `applicant-ui`-specific CSP is set!
        forwardReports: ['deprecation', 'intervention'],
        sessionSampleRate,
        service,
        site,
        version,
      });

      const datadogRumBeforeSend = createBeforeSend<RumEvent>([
        // CSP Reports also surface through RUM, see note above about Cloudflare
        filterCspReportErrors,
        filterVungleErrors,
        logWarningOnThirdPartyJsError,
      ]);
      datadogRum.init({
        allowedTracingOrigins,
        applicationId,
        beforeSend: datadogRumBeforeSend,
        clientToken,
        defaultPrivacyLevel: 'mask',
        env,
        sessionSampleRate,
        sessionReplaySampleRate: 0,
        service,
        site,
        version,
      });

      [datadogLogs, datadogRum].forEach(datadogSdk =>
        datadogSdk.setGlobalContextProperty('ftn', { tenant }),
      );
    })
    // eslint-disable-next-line no-console
    .catch(e => console.error(e));
};
